# Copied from the rails_client_logger gem source so we can bring it into webpack

window.jsLoggerBasePath ||= ''
window.jsLoggerUrl ||= "/logger/rails_client_logger/log"

window.jsLogger =
  invoke: (level, message) ->
    jQuery.ajax {
      type: 'post'
      beforeSend: (xhr) ->
        xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content'))
      data: {level: level, message: message}
      complete: (request) ->
        # Nothing yet
      url: "" + window.jsLoggerBasePath + window.jsLoggerUrl
    }

  debug: (message) ->
        @invoke('debug', message)

  info: (message) ->
        @invoke('info', message)

  warn: (message) ->
        @invoke('warn', message)

  error: (message) ->
        @invoke('error', message)

  fatal: (message) ->
        @invoke('fatal', message)

window.onerror = (message, filename, lineno, colno, error) ->
  # These "google not defined" errors tend to be from bots (esp. from China)
  if message.match(/google is not defined|google”未定义|Can't find variable: google/i)
    return

  if !document.ignore_js_errors && !message.match(/Script error.?$/i)
    window.jsLogger.fatal(filename + ":" + lineno + "\nDetails: " + message)
      