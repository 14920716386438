import "core-js/stable";
import "isomorphic-unfetch";
import "regenerator-runtime/runtime";

import Appsignal from "@appsignal/javascript";
import { plugin as pluginWinEvent } from "@appsignal/plugin-window-events";
import { plugin as pluginBreadConsole } from "@appsignal/plugin-breadcrumbs-console";
import { plugin as pluginBreadNet } from "@appsignal/plugin-breadcrumbs-network";
import { plugin as pluginPathDec } from "@appsignal/plugin-path-decorator";
if (document.body.dataset.env == "production") {
  const appsignal = new Appsignal({
    key: "e40ec8bd-defc-4a91-9984-805e70d6df18",
    revision: window.revision,
  });
  appsignal.use(pluginWinEvent());
  appsignal.use(pluginBreadConsole());
  appsignal.use(pluginBreadNet());
  appsignal.use(pluginPathDec());
} else if (document.body.dataset.env == "demo") {
  const appsignal = new Appsignal({
    key: "e40ec8bd-defc-4a91-9984-805e70d6df18",
    revision: window.revision,
  });
  appsignal.use(pluginWinEvent());
  appsignal.use(pluginBreadConsole());
  appsignal.use(pluginBreadNet());
  appsignal.use(pluginPathDec());
}

// For styles that are used on more than one page are not directly linked
// to a single component/container (mostly for historical reasons).
import "../app/global_styles/layout.scss";
import "../app/global_styles/components/input_with_icon.scss";
import "../app/global_styles/components/selector_list.scss";
import "../app/global_styles/components/flight.scss";
import "../app/global_styles/components/reservation.scss";
import "../app/global_styles/components/service_extras_selector.scss";
import "../app/global_styles/components/site_and_location.scss";
import "../app/global_styles/components/location.scss";

// import '../app/utils/appsignal'
import "../app/utils/rails_client_logger";
